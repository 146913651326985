/* ---------------------------------------------------
    GLOBAL
----------------------------------------------------- */
body {
	background-color: #f4f4f4;
	color: #3c4858;
	font-size: 14px;
	font-weight: 300;
	min-width: 425px;
}

body > iframe {
	pointer-events: none;
	display: none;
}

main {
	overflow-y: auto;
}

.table-action-button {
    color: #79C1DB !important;
}

.table-action-button:hover {
    color: #65B3D0;
}

.table-action-button.isDisabled {
    color: #65B3D0;
}

.border-bottom {
	border-bottom: 1px solid #9e9e9e;
}

.pre-scrollable-400 {
	max-height: 400px;
	overflow-y: scroll;
	z-index: 99;
}

.prevent-select {
	user-select: none !important;
}

/* ---------------------------------------------------
    TABLE
----------------------------------------------------- */
.table > thead > tr > th {
	font-weight: 500;
}

.table-responsive > .table-bordered {
	border: 1px solid #dee2e6;
}

/* ---------------------------------------------------
    BACKGROUND
----------------------------------------------------- */
.bg-primary {
	background-color: #79C1DB !important;
}

.bg-secondary {
	background-color: #6c757d !important;
}

.bg-success {
	background-color: #28a745 !important;
}

.bg-danger {
	background-color: #dc3545 !important;
}

.bg-warning {
	background-color: #ffc107 !important;
}

.bg-info {
	background-color: #17a2b8 !important;
}

.bg-dark {
	background-color: #343a40 !important;
}

.bg-light {
	background-color: #eeeeee !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.bg-brown-light {
	background: #8c8279 !important;
}

.bg-brown-default {
	background: #6e6259 !important;
}

.bg-brown-hight {
	background: #362b22 !important;
}

.bg-grey-light {
	background: #b8b8b83b !important;
}

.text-primary {
	color: #79C1DB;
	font-weight: bold;
}

/* ---------------------------------------------------
    BUTTONS
----------------------------------------------------- */
.btn-primary {
	background-color: #79C1DB !important;
	border-color: #79C1DB;
}

.btn-dark {
	background-color: #000 !important;
	border-color: #000;
}

.btn-outline {
	border: 1px solid;
}

.btn-outline-primary {
	color: #79C1DB;
	background-color: transparent;
	background-image: none;
	border-color: #79C1DB !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
	color: #fff;
	background-color: #65B3D0;
	border-color: #65B3D0;
}

.btn-outline-opea {
	color: #630A37;
	background-color: #ffffff;
	background-image: none;
	border-color: #630A37 !important;
}

.btn-outline-opea:hover,
.btn-outline-opea:active,
.btn-outline-opea:focus {
	color: #630A37 !important;
	background-color: #ffffff !important;
	border-color: #630A37 !important;
}

.btn-outline-secondary {
	color: #999999;
	background-color: transparent;
	background-image: none;
	border-color: #999999 !important;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
	color: #fff;
	background-color: #999999;
	border-color: #999999;
}

.btn-outline-success {
	color: #4caf50;
	background-color: transparent;
	background-image: none;
	border-color: #4caf50 !important;
}

.btn-outline-success:hover,
.btn-outline-success:active,
.btn-outline-success:focus {
	color: #fff;
	background-color: #4caf50;
	border-color: #4caf50;
}

.btn-outline-danger {
	color: #f44336;
	background-color: transparent;
	background-image: none;
	border-color: #f44336 !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-outline-danger:focus {
	color: #fff;
	background-color: #f44336;
	border-color: #f44336;
}

.btn-outline-danger {
	color: #f44336;
	background-color: transparent;
	background-image: none;
	border-color: #f44336 !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-outline-danger:focus {
	color: #fff;
	background-color: #f44336;
	border-color: #f44336;
}

.btn-outline-warning {
	color: #ff9800;
	background-color: transparent;
	background-image: none;
	border-color: #ff9800 !important;
}

.btn-outline-warning:hover,
.btn-outline-warning:active,
.btn-outline-warning:focus {
	color: #fff;
	background-color: #ff9800;
	border-color: #ff9800;
}

.btn-outline-info {
	color: #79C1DB;
	background-color: transparent;
	background-image: none;
	border-color: transparent !important;
}

.btn-outline-info:hover,
.btn-outline-info:active,
.btn-outline-info:focus,
.btn-active {
	color: #fff;
	background-color: #65B3D0;
	border-color: #65B3D0;
}

.btn-outline-light {
	color: #999999;
	background-color: transparent;
	background-image: none;
	border-color: #999999 !important;
}

.btn-outline-light:hover,
.btn-outline-light:active,
.btn-outline-light:focus {
	color: #000;
	background-color: #d7d7d7;
	border-color: #999999;
}

.btn-outline-dark {
	color: #000;
	background-color: transparent;
	background-image: none;
	border-color: #000 !important;
}

.btn-outline-dark:hover,
.btn-outline-dark:active,
.btn-outline-dark:focus {
	color: #fff;
	background-color: #000;
	border-color: #999999;
}

.btn-group-sm .btn.btn-fab,
.btn-group-sm .btn.btn-just-icon,
.btn-group-sm > .btn.btn-fab,
.btn-group-sm > .btn.btn-just-icon,
.btn.btn-fab.btn-fab-mini,
.btn.btn-fab.btn-sm,
.btn.btn-just-icon.btn-fab-mini,
.btn.btn-just-icon.btn-sm {
	height: 30px;
	min-width: 30px;
	width: 30px;
}

.btn-add-block {
	background: white;
	float: right;
}

.btn-link {
    color: #79C1DB; 
    text-decoration: none;
}

.btn-link:hover {
    color: #65B3D0;
}

.btn-link-opea {
    color: #630A37; 
}

.btn-link-opea:hover {
    color: #52072c;
}

.nav-pills > li > a {
	border-radius: 0 !important;
	color: #362b22;
}

.nav-pills > li > a:hover {
	background-color: #63b546;
	color: #ffffff;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
	background-color: white;
	color: #362b22;
	box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(153, 153, 153, 0.2);
	border-radius: 0;
	border-bottom: 4px solid #63b546 !important;
}

/* ---------------------------------------------------
    ALERTS
----------------------------------------------------- */
.alert.alert-info {
	background-color: #8c8279;
}
.alert-info.alert-info-original {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
}

/* ---------------------------------------------------
    WELL
----------------------------------------------------- */
.well {
	min-height: 20px;
	background-color: #f5f5f5;
	border: 1px solid #e3e3e3;
	border-radius: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

/* ---------------------------------------------------
    FORM
----------------------------------------------------- */
.more-fields .more-fields-item {
	margin-bottom: 1.5rem;
}
.more-fields .more-fields-item:last-child {
	margin-bottom: 0;
}
.minus-box-btn {
	width: 66px;
}
.card {
	background: #FAFAFA;
	border: none;
}
.more-fields .card {
	background: none;
	border: 1px solid rgba(0, 0, 0, 0.14);
}

.form-group label.control-label {
	color: #000;
}

.form-group .form-control {
	margin-bottom: 12px;
}

.well {
	background-color: white;
}

/* Espaçamento dos INPUTS */
.dropdown-list-select {
	padding: 7px 0;
}

.icon-margin-l-5 {
	margin-left: 5px;
}
.btn-group-justified .btn {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	padding: 10px 15px;
	display: block;
}
/* Global Button Styles */
a.animated-button:link,
a.animated-button:visited {
	display: block;
	text-decoration: none;
	overflow: hidden;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
a.animated-button:link:after,
a.animated-button:visited:after {
	content: "";
	position: absolute;
	height: 0%;
	left: 50%;
	top: 50%;
	width: 150%;
	z-index: -1;
	-webkit-transition: all 0.75s ease 0s;
	-moz-transition: all 0.75s ease 0s;
	-o-transition: all 0.75s ease 0s;
	transition: all 0.75s ease 0s;
}
a.animated-button:link:hover,
a.animated-button:visited:hover {
	color: #fff;
	text-shadow: none;
}
a.animated-button:link:hover:after,
a.animated-button:visited:hover:after {
	height: 450%;
}
a.animated-button.thar-three {
	color: #fff;
	cursor: pointer;
	display: block;
	position: relative;
	border: 1px solid #6e6259;
	background-color: rgba(140, 130, 121, 1);
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
	color: #fff !important;
	background-color: transparent;
}
a.animated-button.thar-three:before {
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 0px;
	z-index: -1;
	content: "";
	color: #fff !important;
	background: #6e6259;
	transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover:before,
a.animated-button.active:before {
	left: 0%;
	right: auto;
	width: 100%;
}
@supports (-ms-ime-align: auto) and (-webkit-text-stroke: initial) {
	.label-floating input.form-control {
		padding: 0;
		margin: 0;
		float: left;
		width: 100%;
	}

	.dropdown-list-select {
		padding: 0;
		margin: 0;
		float: left;
		width: 100%;
	}

	.label-floating {
		margin-bottom: 40px;
	}
}

/* ---------------------------------------------------
    HEADER and SIDEBAR
----------------------------------------------------- */
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
	height: calc(100vh - 85px);
}

.sidebar .logo,
.off-canvas-sidebar .logo {
	padding: 15px 23px;
}

.animation-transition-general,
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a,
.navbar-user,
.sidebar .nav i,
.off-canvas-sidebar .nav i {
	color: white;
}

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
	margin: 10px 0;
	border-radius: 0;
}

.sidebar-item-selected {
	background-color: rgba(255, 255, 255, 0.2);
}

.sidebar .nav i,
.off-canvas-sidebar .nav i {
	font-size: 1.3em;
}

.main-panel > .content {
	margin-top: 0;
}

.navbar-user {
	padding: 17px 0;
	border-radius: 0;
}

.btn-sidebar-icon svg,
.btn-sidebar span {
	color: white;
	font-weight: 300;
	font-size: 14px;
}
.btn-sidebar-icon svg {
	font-size: 24px;
}

/* ---------------------------------------------------
    CUSTOM SEARCH
----------------------------------------------------- */
.input-group-search {
	margin-top: 10px;
}

.input-group-search .form-group {
	padding-bottom: 0;
	margin: 1px -12px 0 0;
	border: 1px solid #d2d2d2;
}

.input-group-search .form-group input {
	margin-bottom: 0;
	padding: 0 0 0 10px;
	min-height: 2.8em;
}

.input-group-search .css-1aya2g8 {
	height: 41.5px;
	margin-right: -2px;
}

/* ERROS */

.sp-error {
	min-height: 12px;
	line-height: 16px;
	font-weight: 400;
    /* padding-top: 4px; */
}

/* ---------------------------------------------------
    ADD BUTTON FIXED DASHBOARD
----------------------------------------------------- */
.float {
	position: fixed;
	width: 55px;
	height: 55px;
	bottom: 40px;
	right: 40px;
	background-color: #006F8F;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
	z-index: 99;
}

.float:hover {
	color: #fff;
}

.add-float {
	font-size: 24px;
	margin-top: 17px;
}

#presentation {
	width: 480px;
	height: 120px;
	padding: 20px;
	margin: auto;
	background: #fff;
	margin-top: 10px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	transition: all 0.3s;
	border-radius: 3px;
}

#presentation:hover {
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	transition: all 0.3s;
	transform: translateZ(10px);
}

#floating-button {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #17D498;
	position: fixed;
	bottom: 40px;
	right: 40px;
	cursor: pointer;
	box-shadow: 0px 2px 5px #666;
}

.plus {
	color: white;
	position: absolute;
	top: 0;
	display: block;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	padding: 0;
	margin: 0;
	line-height: 36px;
	font-size: 38px;
	font-family: "Roboto";
	font-weight: 300;
	animation: plus-out 0.3s;
	transition: all 0.3s;
}

#container-floating {
	position: fixed;
	width: 70px;
	height: 70px;
	bottom: 30px;
	right: 30px;
	z-index: 150;
}

#container-floating:hover {
	height: 400px;
	width: 90px;
	padding: 30px;
}

#container-floating:hover .plus {
	animation: plus-in 0.15s linear;
	animation-fill-mode: forwards;
}

.edit,
.edit-sub-menu {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	display: block;
	right: 0;
	padding: 0;
	opacity: 0;
	margin: auto;
	transform: rotateZ(-70deg);
	transition: all 0.3s;
	animation: edit-out 0.3s;
}

#container-floating:hover .edit {
	line-height: 57px;
	text-align: center;
	font-size: 2em;
	color: white;
	animation: edit-in 0.2s;
	animation-delay: 0.1s;
	animation-fill-mode: forwards;
}

#container-floating:hover .edit-sub-menu {
	line-height: 40px;
	font-size: 1.5em;
}

@keyframes edit-in {
	from {
		opacity: 0;
		transform: rotateZ(-70deg);
	}
	to {
		opacity: 1;
		transform: rotateZ(0deg);
	}
}

@keyframes edit-out {
	from {
		opacity: 1;
		transform: rotateZ(0deg);
	}
	to {
		opacity: 0;
		transform: rotateZ(-70deg);
	}
}

@keyframes plus-in {
	from {
		opacity: 1;
		transform: rotateZ(0deg);
	}
	to {
		opacity: 0;
		transform: rotateZ(180deg);
	}
}

@keyframes plus-out {
	from {
		opacity: 0;
		transform: rotateZ(180deg);
	}
	to {
		opacity: 1;
		transform: rotateZ(0deg);
	}
}

.nds {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	position: fixed;
	z-index: 300;
	transform: scale(0);
	cursor: pointer;
}

.nd1 {
	background: #d3a411;
	right: 50px;
	bottom: 120px;
	animation-delay: 0.2s;
	animation: bounce-out-nds 0.3s linear;
	animation-fill-mode: forwards;
}


.nd3 {
	background: #3c80f6;
	right: 50px;
	bottom: 180px;
	animation-delay: 0.15s;
	animation: bounce-out-nds 0.15s linear;
	animation-fill-mode: forwards;
}

.nd4 {
	background: #8c8279;
	right: 50px;
	bottom: 240px;
	animation-delay: 0.2s;
	animation: bounce-out-nds 0.3s linear;
	animation-fill-mode: forwards;
}

.nd5 {
	background: #8c8279;
	right: 50px;
	bottom: 300px;
	animation-delay: 0.2s;
	animation: bounce-out-nds 0.3s linear;
	animation-fill-mode: forwards;
}

.blue-button {
	background: #3c80f6 !important;
}

.yellow-button {
	background: #d3a411 !important;
}

.dark-grey-button {
	background: #8c8279 !important;
}

@keyframes bounce-nds {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes bounce-out-nds {
	from {
		opacity: 0;
		transform: scale(0);
	}
	to {
		opacity: 0;
		transform: scale(0);
	}
}

#container-floating:hover .nds {
	animation: bounce-nds 0.1s linear;
	animation-fill-mode: forwards;
}

#container-floating:hover .nd3 {
	animation-delay: 0.08s;
}
#container-floating:hover .nd4 {
	animation-delay: 0.15s;
}
#container-floating:hover .nd5 {
	animation-delay: 0.2s;
}

/* ---------------------------------------------------
    LOGIN
----------------------------------------------------- */
.login-page .page-header {
	background-image: url("../img/img_login_yatta_opea.jpg");
	background-size: cover;
	background-color: #FAFAFA;
    overflow-y: auto;
	/* background-position: top center; */
	/*background-blend-mode: multiply;*/
}

.form-content-login .welcome {
	font-family: "Baloo Tamma 2", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.login-page .content {
    display: flex;
}

.login-page .content .text-box {
    font-family: 'Arial', sans-serif;
    font-size: 20px;
    margin-right: 30px;
}

.login-page .content .text-box span {
    color: #630A37;
    font-size: 50px;
}

.login-page .content .text-box strong {
    color: #630A37;
    font-weight: bold;
}
.login-page .card-login {
	font-size: 1.3em;
  	background-color: #ffffff;
}


.login-page .esqueci-minha-senha {
	padding: 0 15px;
	color: #e1671f;
}

.login-page .esqueci-minha-senha:hover {
	text-decoration: underline;
	color: #e1671f;
}

.login-page button {
	font-size: 1em;
}

/* ---------------------------------------------------
    INPUTFILE BUTTON
----------------------------------------------------- */
.input-file-content {
	position: relative;
    height: 90%;
	width: max-content;
}
.inputfile {
	width: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	cursor: pointer;
	left: 0;
    top: 0;
    margin: 0;
    height: 100%;
    padding: 0;
}
.inputfile-1 + label {
	color: #ffffff;
	background-color: #630A37;
	border-radius: 4px;
}
.inputfile + label {
	max-width: 100%;
	font-size: 1.25rem;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	padding: 0.625rem 1.25rem;
	margin: 0;
}
.inputfile + label svg {
	width: 1em;
	height: 1em;
	vertical-align: middle;
	fill: currentColor;
	margin-top: -0.25em;
	margin-right: 0.25em;
}

/* ---------------------------------------------------
    SCHEDULER
----------------------------------------------------- */
.scheduler-border {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width: 100%;
	padding: 10px 15px;
	margin-bottom: 0;
	top: -23px;
	background-color: #A57C7C;
	border: 1px solid #ccc;
	border-radius: 0;
	color: white;
}
.scheduler-action {
	width: calc(100% - 3rem);
	position: absolute;
	display: flex;
	align-items: center;
}
.scheduler-border-scrollable {
	position: absolute;
	top: 246px;
	width: 93%;
	z-index: 5;
}

/* ---------------------------------------------------
    PAGINATION
----------------------------------------------------- */
.pagination > .page-item > .page-link,
.pagination > .page-item > span {
	/* border: 0; */
	z-index: 0 !important;
	border-radius: 4px !important;
	border-color: #A57C7C;
	box-shadow: none;
	transition: all 0.3s;
	padding: 0 11px;
	margin: 0 3px;
	min-width: 30px;
	height: 30px;
	line-height: 30px;
	color: #A57C7C;
	font-weight: 400;
	font-size: 12px;
	text-transform: uppercase;
	background: 0 0;
	background-color: transparent;
	text-align: center;
	cursor: pointer;
}

.pagination > .page-item.active > a,
.pagination > .page-item.active > a:focus,
.pagination > .page-item.active > a:hover,
.pagination > .page-item.active > span,
.pagination > .page-item.active > span:focus,
.pagination > .page-item.active > span:hover {
	background-color: #A57C7C;
	color: #ffffff;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}

.pagination-lg .page-link {
	padding: 0.75rem 0;
	font-size: 1.25rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0;
	font-size: 0.875rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

/* CUSTOM DEV RB */
/* NAV */

.nav-tabs > li {
	margin-bottom: 0px;
}
.nav-tab-rb > li.active > a:hover,
.nav-tab-rb > li > a:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.pointer {
	cursor: pointer;
}

.tab-desp {
	border-bottom: 2px solid transparent;
	width: 100%;
	transition: all 0.3s;
}
.tab-desp.active {
	border-bottom: 2px solid #630A37;
}

/* CLASSES UTILS */
/* remover margin do row */
.main-panel {
	width: 100%;
}
@media (min-width: 992px) {
	.main-panel {
		padding-left: 260px;
		width: 100%;
	}
	.sidebar {
		z-index: 50;
	}
}

.ext-value {
	font-weight: bold;
}

.ext-title {
	font-weight: normal;
	text-transform: capitalize !important;
	font-size: 0.8em;
}

.to-lower {
	text-transform: lowercase;
}

/* Deixa o DropdownList com a mesma apar�ncia dos demais inputs */

.rw-dropdown-list > * {
	background-color: inherit !important;
}

.rw-combobox > *,
.rw-combobox > * > * {
	background-color: inherit !important;
}

legend {
	color: #fff;
}

/* ---------------------------------------------------
    TOOLTIP
----------------------------------------------------- */

.tooltip-custom {
	margin: 0px;
	padding: 0px;
	font-weight: 400;
	z-index: 1201 !important;
}
.tooltip-custom:after {
	border-top-color: #ffffff !important;
	background-color: #ffffff !important;
}
.tooltip-custom.type-warning {
	color: #524235;
}

.float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #006F8F;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
	cursor: pointer;
}

.my-float {
	margin-top: 22px;
	color: white;
}

.btn-circle {
	width: 50px !important;
	height: 50px !important;
	padding: 6px 0px !important;
	border-radius: 30px !important;
	text-align: center !important;
	font-size: 12px !important;
	line-height: 1.42857 !important;
	background-color: #17D498 !important;
}

/* ---------------------------------------------------
    CHECKBOX
----------------------------------------------------- */

.pure-checkbox input[type="checkbox"]:checked + label:before,
.pure-radiobutton input[type="checkbox"]:checked + label:before {
	animation: borderscale 200ms ease-in;
	background: #63b546;
}

.pure-checkbox input[type="checkbox"] + label:after,
.pure-radiobutton input[type="checkbox"] + label:after,
.pure-checkbox input[type="radio"] + label:after,
.pure-radiobutton input[type="radio"] + label:after {
	background-color: #63b546;
}

.pure-checkbox-toggle input[type="checkbox"] + label:before {
	background-color: #63b546;
}

/* ---------------------------------------------------
    ICON
----------------------------------------------------- */
.alert-icon {
	font-size: 2rem !important;
	margin-bottom: 1rem;
}

.table-label-checkbox div label {
	margin-left: 0;
	margin-right: 0;
}

.isDisabled {
	color: currentColor;
	cursor: not-allowed;
	opacity: 0.5;
	text-decoration: none;
}

select[readonly] {
	color: #555 !important;
	pointer-events: none;
	touch-action: none;
}

.cursorNotAllowed {
	cursor: not-allowed !important;
}

/*---------------------------------------*\
|::::::::::: CUSTOM OVERFLOW :::::::::::::|
\*---------------------------------------*/
.beauty-overflow-x::-webkit-scrollbar,
.beauty-overflow-y::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
}
.beauty-overflow-x::-webkit-scrollbar{
  height: 0.7em;
}
.beauty-overflow-y::-webkit-scrollbar{
  width: 0.7em;
}
.beauty-overflow-x::-webkit-scrollbar-thumb, .beauty-overflow-y::-webkit-scrollbar-thumb{
  border-radius: 2em;
  background-color: rgba(0, 0, 0, 0.2);
}
.beauty-overflow-x::-webkit-scrollbar-thumb{
  height: 0.7em;
}
.beauty-overflow-y::-webkit-scrollbar-thumb{
  width: 0.7em;
}
.beauty-overflow-x::-webkit-scrollbar-thumb:hover, .beauty-overflow-y::-webkit-scrollbar-thumb:hover{
  background-color: rgba(0, 0, 0, 0.5);
}

.chip-bg-green{
	color: #ffffff !important;
	background-color: #96d600 !important;
  	font-weight: 500;
}
.chip-bg-red{
	color: #ffffff;
	background-color: #db4c46 !important;
	font-weight: 500;
}
.chip-bg-yellow{
	color: #ffffff;
	background-color: #ffc700 !important;
	font-weight: 500;
}
.txt-align-left{
	text-align: left !important;
}
.link-input {
	position: absolute;
	top: 8px;
  left: 96%;
  font-size: 20px;
}
.link-col {
	max-width: 95.666667%;
}

.bold-label {
	font-weight: bold !important;
  font-size: 16px !important;
  color: #8c8279 !important;
}

.btn-choose-file-default{
  color: #0290FF !important;
	border-color: #0290FF !important;
  min-height: 36px !important;
}

.btn-choose-file-default:hover{
  background-color: #f1f5fb !important;
}

.btn-choose-file-default:disabled{
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.white-background{
	background-color: #ffffff;
}

.label-info-yellow {
	background-color: #FFC10740;
    border: 1px solid #FFC107;
    color: #6E6259;
    opacity: 1;
    text-align: center;
}

.label-info-grey {
	background-color: #e1dfdc;
    border: 1px solid #c5c1bc;
    color: #6e6259;
    opacity: 1;
    text-align: center;
}

.files-area {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 2px #00000029;
	border: 1px solid #E1DFDC;
	border-radius: 4px;
	opacity: 1;
	align-items: center;
	overflow-y: scroll;
    height: 13em;
}

.justify-content-documents {
	justify-content: start;
}

.modal-descricao-ordem {
	margin-left: 20%;
}

.expand-sumary-participantes {
	padding: 0 9px 0 9px !important;
}

.MuiExpansionPanelSummary-root-224 {
    padding: 0 10px 0 10px !important;
}

.MuiExpansionPanelDetails-root-230 {
    padding: 8px 24px 10px !important;
}

.MuiDialogTitle-root-349 {
    flex: 0 0 auto !important;
    margin: 0 !important;
	padding: 24px 24px 8px !important;
}

.participante-search-input {
	margin: 5px 0px !important;
}
	
.empty-item-label-paticipante {
	font-size: 12pt;
	font-weight: bold;
}

.drop-area-documentos {
	flex: 0 0 28% !important;
    max-width: 27.5% !important;
}

.icon-light-green {
	color: #96D600;
}

.icon-light-grey {
	color: #E1DFDC;
}

.border-bottom-thin-light-grey {
	border-bottom: 0.2em solid #E1DFDC !important;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

.title-modal-investidor {
	flex: 0 0 auto ;
    margin: 0 ;
    padding: 15px 24px 5px !important ;
}

.title-modal-cedoc {
	flex: 0 0 auto ;
    margin: 0 ;
    padding: 15px 24px 5px !important ;
	display: none;
}

.container-modal-cedoc .logbook-modal__paper{
	max-width: 500px !important;
}

.container-modal-cedoc .logbook-modal__paper .logbook-modal__container{
	min-width: unset;
	padding: 24px 24px 24px;
}

.label-grey-bold {
	color: #BAB5AF;
	opacity: 1;
	font-weight: bold;
}

.label-dark-grey-bold {
	color: #6E6259;
	opacity: 1;
	font-weight: bold;
}

.icon-blocked-user {
	width: 28px !important;
	height: 28px !important;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 4px;
}

.icon-aceitar-votos {
	top: 659px;
	left: 1189px;
	width: 43px !important;
	height: 27px !important;
	border-radius: 14px;
	opacity: 1;
}

.liberar-votos-icon {
	display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    justify-content: flex-start !important;
    margin-left: 2px !important;
    flex: 0 0 25%;
    max-width: 7.8em;
    margin-top: 2px;
}

.modal-investidor-row {
	height: 45px;
	box-shadow: 3px 2px 2px #00000029;
	display:flex;
	margin-top: 5px !important;
}

.row-align-content-center {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.modal-investidor-pagination ul {
	margin-bottom: 5px !important;
}

.hr-modal-investidor {
	margin-top: 5px !important;
	margin-bottom: 5px !important;
}

.content-acesso-portal {
	width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    margin-right: auto;
    margin-left: auto;
}

.label-documents-acesso-portal {
	font-weight: bold;
	font-size: 18px;
	color: #8c8279;
}

.label-warning-document-acesso-portal {
	margin-top: -1px !important;
	margin-left: 7px !important;
	font-size: 0.9rem !important;
}

.file-area-detalhar-acesso-portal {
	margin-left: 1.5em;
	flex: 0 0 100% !important;
	max-width: 96.8% !important;
}

.info-solicitacao-participacao-modal {
	width: 60rem !important;
	height: 92px !important;
	border-radius: 3px;
}

.liberar-solicitacao-participacao-modal {
	top: 346px;
	left: 1139px;
	width: 206px;
	height: 92px;
	border: 1px solid #E1DFDC;
	border-radius: 3px;
	opacity: 1;
	margin-left: 1em;
}

.download-procuracao {
	background: #79C1DB 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 2px #00000029;
	opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.procuracao-icon {
	margin-top: 5px;
    font-size: 1.3em;
}

.col-3-investidores {
	flex: 0 0 18%;
	max-width: 18%;
}

.col-2-investidores {
	flex: 0 0 15%;
	max-width: 15%;
	word-break: break-word;
}

.col-1-investidores {
	flex: 0 0 10%;
	max-width: 10%;
}

.col-2-resumo {
    flex: 0 0 13.666667%;
    max-width: 13.666667%;
    padding-left: 1em;
    padding-right: 0.8em;
}

.upload-button-position-estrutura-operacao{
	top: -1.3em !important;
    position: relative;
}

.label-estrutura-operacao {
	color: #000;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.07143;
}
.word-break-all{
	word-break: break-all;
}

/*  */

.modalDialog [class^="MuiPaper-root"] {
	flex-direction: unset;
	overflow: hidden;
}

/* ---------------------------------------------------
    DASHBOARD
----------------------------------------------------- */

.internal-aspect-ratio {
	aspect-ratio: 1.8;
}
.chart-title {
    font-size: 24px;
}

/* ---------------------------------------------------
    CARD VALUES DESCRIPTION
----------------------------------------------------- */
.card-value-description {
	background-color: #fff;
    /* box-shadow: 0px 2px 6px #00000029;
    padding: 20px; */
	/* width: 200px;
	height: 130px; */
	width: 100%;
	height: 139px;
}

.title-card-value-description {
	font-size: 30px;
	color: #630A37;
	font-weight: bold;
}

.box-icon-card-value-description {
	width: 60px;
	height: 50px;
	background: #A57C7C 0% 0% no-repeat padding-box;
	border-radius: 5px;
	font-size:26px;
	opacity: 1;
	display: flex;
    align-items: center;
    justify-content: center;
}

.icon-card-value-description {
	color: #FFFFFF;
	opacity: 1;
}

.card-value-footer-text {
  font-size: 14px;
  /* margin-top: 16px; */
  color: #A57C7C;
}

.card-footer {
  background-color: unset;
}

.center-head {
	background-color: #F5F6FA !important;
    color: #A3A6B4 !important;
	display: flex;
    align-items: center;
    justify-content: center;
}

.description-card-value {
	text-align: left;
	font: normal normal normal 20px/24px Roboto;
	letter-spacing: 0px;
	line-height: 30px;
	margin-top:15px;
	font-weight: 400;
	opacity: 1;
}

.customGrid {
	justify-content:center;
	text-align:-webkit-center;
	width:unset !important;
	margin-right:-15px;
    margin-left:-15px;
}

.badge-secondary {
    color: #ffffff;
    background-color: #A57C7C;
}

.page-head__title {
  color: #630A37;
  font-weight: bold;
}

.rounded-12 {
	border-radius: 12px;
}

.rounded-8 {
	border-radius: 8px;
}

.shadow-custom {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

/* .disabled-field {
	color: currentColor;
	pointer-events: none;
	opacity: 0.5;
	text-decoration: none;
	cursor: not-allowed;
} */

.inputDisabled{
	color: currentColor;
	pointer-events: none;
	opacity: 0.5;
	text-decoration: none;
	cursor: not-allowed;
}

/* NEW MODELS ---------------------------- */

/*  */

.text-underline {
	text-decoration: underline !important;
}

/* COLORS */

.text-dark-imp {
	color: #343a40 !important
}

.text-dark-hard {
	color: #000000de !important
}

.color-grey {
	color: #B8B8B8 !important;
}

.bg-bege-wrng {
  background-color: #FFF6F5;
}

.txt-blue-link {
	color: #79C1DB !important;
}

.txt-blue-link-dark {
	color: #006F8F !important;
}

.bg-df-opea {
	background-color: #FFFCFB;
}

.bg-df-gray {
	background-color: #E7E7E7;
}

.btn-out-df-imp {
	transition: all 0.3s;
	color: #630A37 !important;
	background-color: #ffffff !important;
	border: 1px solid #630A37 !important;
}

.btn-out-df-imp {
	color: #4d082a !important;
	border-color: #4d082a !important;
}

.p-bg {
	background-color: #630A37;
	transition: all 0.3s;
}

.p-bg:hover {
	background-color: #4d082a;
}

.p-bg-imp {
	background-color: #630A37 !important;
	transition: all 0.3s !important;
}

.p-bg-imp:hover {
	background-color: #4d082a !important;
}

.scs-bg-imp {
	background-color: #83BF74 !important;
	transition: all 0.3s !important;
}

.scs-bg-imp:hover {
	background-color: #67a158 !important;
}

.scs-bg-dark-imp {
	background-color: #198754 !important;
	transition: all 0.3s !important;
}

.scs-bg-dark-imp:hover {
	background-color: #126b41 !important;
}

.err-bg-imp {
	background-color: #DC3545 !important;
	transition: all 0.3s !important;
}

.err-bg-imp:hover {
	background-color: #b62533 !important;
}

.default-bg-imp {
	background-color: #630A37 !important;
	transition: all 0.3s !important;
}

.default-bg-imp:hover {
	background-color: #52072c !important;
}

.warn-bg-imp {
	background-color: #FFC107 !important;
	transition: all 0.3s !important;
}

.warn-bg-imp:hover {
	background-color: #e0ab0b !important;
}

.blue-bg-imp {
	background-color: #79C1DB !important;
	transition: all 0.3s !important;
}

.blue-bg-imp:hover {
	background-color: #59a2bd !important;
}

.darkblue-bg-imp {
	background-color: #006F8F !important;
	transition: all 0.3s !important;
}

.darkblue-bg-imp:hover {
	background-color: #015369 !important;
}

.p-color {
	color: #630A37;
}

.p-color-imp {
	color: #630A37 !important;
}

.p-color-lighten {
	color: #A57C7C;
}

.p-color-lighten-imp {
	color: #A57C7C !important;
}

button.scs-bg-imp {
	border: 1px solid #83BF74 !important;
}

button.err-bg-imp {
	border: 1px solid #DC3545 !important;
}

button.default-bg-imp {
	border: 1px solid #630A37 !important;
}

button.warning-bg-imp {
	border: 1px solid #e0ab0b !important;
}

button:disabled {
	opacity: 0.5 !important;
	background-color: #e7e7e7 !important;
	pointer-events: none !important;
	border: 1px solid #B8B8B8 !important;
	color: #000000 !important;
}

.table-files-divider {
	width: 1px;
	height: 20px;
	background-color: #6363637c;
}


/* FONTS */

.f-8 {
	font-size: 8px;
}
.f-10 {
	font-size: 10px;
}
.f-10-5 {
	font-size: 10.5px;
}
.f-10-5-imp {
	font-size: 10.5px !important;
}
.f-12 {
	font-size: 12px;
}
.f-12-imp {
	font-size: 12px !important;
}
.f-14 {
	font-size: 14px;
}
.f-14-imp {
	font-size: 14px !important;
}
.f-16 {
	font-size: 16px;
}
.f-18 {
	font-size: 18px;
}
.f-20 {
	font-size: 20px;
}
.f-22 {
	font-size: 22px;
}
.f-24 {
	font-size: 24px;
}
.f-28 {
	font-size: 28px;
}
.f-34 {
	font-size: 36px;
}
.f-36 {
	font-size: 36px;
}
.f-40 {
	font-size: 40px;
}
.f-46 {
	font-size: 46px;
}

/* LINE HEIGHTS */

.lh-12 {
	line-height: 12px;
}

.lh-22 {
	line-height: 22px;
}

/* OPACITY */

.opacity-25 {
	opacity: 0.75;
}

.opacity-50 {
	opacity: 0.50;
}

.opacity-75 {
	opacity: 0.25;
}

.opacity-100 {
	opacity: 0;
}

.opacity-100-imp {
	opacity: 0 !important;
}

/* POINTER EVENTS */

.pe-none {
	pointer-events: none !important;
}

/* PADDINGS */

.input-monit {
	padding: 3px;
}

.pb-card-af {
	padding-bottom: 4rem;
}

.py-1-5 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.px-2-5 {
  padding-right: 12px;
  padding-left: 12px;
}

.pdt-2 {
	padding-top: 2px !important;
}

.pd-12 {
	padding: 12px;
}

.pd-input-df {
	padding: 6px 12px !important;
}

.pd-10 {
	padding: 10px;
}

.pdl-28 {
	padding-left: 28px !important;
}

/* WEIGHTS */

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-700 {
	font-weight: 700 !important;
}

/* SHADOWNS */

.box-cmt-monit {
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

/* BORDERS */

.rounded-right-imp {
	border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.brd-monit-input {
	border: 1px solid #B8B8B8 !important;
}

.brd-1 {
	border: 1px solid #B8B8B8 !important;
}

.brd-1.disabled {
	border: none !important;
}

.brd-left-1 {
	border-left: 1px solid #B8B8B8 !important;
}

table td {
	border: none !important;
	border-bottom: 1px solid #dee2e6 !important;
}

.brd-p-color-imp {
	border: 1px solid #630A37 !important;
	transition: all 0.3s;
}

.brd-opea-tab-pdd {
	border-bottom: 1px solid transparent;
	transition: all 0.3s;
}
.brd-opea-tab-pdd.active {
	border-bottom: 1px solid #630A37;
}

/* MARGINS */
.mb-1px {
	margin-bottom: 1px;
}
.mt-1px {
	margin-top: 1px;
}
.mt-2px {
	margin-top: 2px;
}
.mt-3px {
	margin-top: 3px;
}
.mt-6px {
	margin-top: 6px;
}
.mt-7px {
	margin-top: 7px;
}
.mt-10px {
	margin-top: 10px;
}
.mr-1-5 {
	margin-right: 12px;
}
@media (max-width: 991px) {
	.mr-1-5 {
		margin-right: 0px;
	}
}
.ml-1-5 {
	margin-left: 12px;
}
@media (max-width: 991px) {
	.ml-1-5 {
		margin-left: 0px;
	}
}
.mt-1p {
	margin-top: 1px;
}
.mt-2p {
	margin-top: 2px;
}
.mt-6p {
	margin-top: 6px;
}
.mb-2p {
	margin-bottom: 2px;
}

/* HEIGHTS */

.maxvh-expired-card {
	min-height: calc(100vh - 160px) !important;
}

.mxh-40 {
	max-height: 40px;
}

.h-33 {
	height: 33px;
}

.lh-normal {
	line-height: normal;
}

.lh-1 {
	line-height: 1;
}

.h-34 {
	height: 34px;
}

.h-36 {
	height: 36px;
}

.h-38 {
	height: 38px;
}

.h-38-imp {
	height: 38px !important;
}

.h-42 {
	height: 42px;
}

/* HTML */

.overflow-x-hidden {
	overflow-x: hidden;
}

/* NEW BUTTONS */

/* SELECTS */
.form-list-select .control-label {
	color: #888888 !important;
}
.form-list-select .dropdown-list-select {
	/* border: 1px solid #B8B8B8 !important; */
	border-radius: 4px !important;
	max-height: 38px;
}
.form-list-select .dropdown-list-select input:not(.rw-input-reset),
.field-floating .rw-dropdown-list-input:not(.rw-input-reset) {
	padding: 0 8px !important;
}
.field-floating .rw-dropdown-list:before,
.field-floating:hover .rw-combobox:before,
.field-floating .rw-combobox:before {
	border: none !important;
	transition: none !important;
}
.form-list-select.disabled-field .dropdown-list-select {
	border: none !important;
}
.form-list-select.disabled-field .dropdown-list-select .rw-select {
	display: none !important;
}
.form-list-select.disabled-field input,
.field-floating.disabled-field .rw-dropdown-list-input {
	/* padding: 0 !important; */
	cursor: auto !important;
}
.field-floating.blocked .rw-dropdown-list-input {
	padding: 0 8px !important;
	border-radius: 4px !important;
	border: 1px solid #B8B8B8 !important;
	background-color: #E7E7E7
}
.appv-pgmt .rw-input {
	white-space: normal;
	line-height: normal;
}
.field-floating.form-list-select.disabled-field .rw-widget-picker input {
	padding: 0 !important;
	border: none !important;
}
.dropdown-list-select .rw-state-readonly>.rw-widget-container {
	border: none !important;
}

.rw-dropdown-list-input {
	color: #000000 !important;
	font-weight: 400 !important;
}

/* INPUT MASK */

.field-floating .control-label {
	color: #888888 !important;
}
.field-floating .form-control {
	border: 1px solid #B8B8B8 !important;
	border-radius: 4px !important;
	padding: 0 8px !important;
	color: #000000;
}
.field-floating textarea.form-control {
	padding: 8px !important;
}
.field-floating:hover:not(.form-list-select):before,
.field-floating:not(.form-list-select):before {
	border: none !important;
	transition: none !important;
}
.field-floating.disabled-field .form-control,
.field-floating .form-control[readonly] {
	/* padding: 0px !important; */
	/* border: none !important; */
}
.field-floating input:disabled {
	border: none !important;
	padding: 0 !important;
}

.field-floating textarea.form-control.blocked {
	padding: 8px !important;
	border: 1px solid #B8B8B8 !important;
	background-color: #E7E7E7;
	cursor: auto;
}
.field-floating .form-control.blocked {
	padding: 0 8px !important;
	border: 1px solid #B8B8B8 !important;
	background-color: #E7E7E7;
	cursor: auto;
}

/* INPUT ASYNC */

.input-async.disabled > div {
	background-color: #8080802c !important;
}
.input-async.disabled > div [class*='multiValue'] :nth-child(1) {
	padding-right: 8px;
	font-weight: 400;
}
.input-async.disabled > div [class*='multiValue'] :nth-child(2) {
	display: none;
}
.input-async.disabled div[class*='singleValue'] {
	color: #333333;
	font-weight: 400;
}

/* STATUS PGMT */

.chip-pgmt {
	height: 24px !important;
	transition: all 0.3s;
	font-weight: 400;
}

.chip-pgmt.sts-1, .chip-pgmt.sts-monit-2 {
	background-color: #B8B8B8 !important;
	border-color: #B8B8B8 !important;
}
.chip-pgmt.sts-2, .chip-pgmt.sts-hist-1, .chip-pgmt.sts-monit-3 {
	background-color: #F0D975 !important;
	border-color: #F0D975 !important;
}
.chip-pgmt.sts-3, .chip-pgmt.sts-hist-2 {
	background-color: #F0C105 !important;
	border-color: #F0C105 !important;
}
.chip-pgmt.sts-4, .chip-pgmt.sts-hist-3 {
	background-color: #0077b566 !important;
	border-color: #0077b566 !important;
}
.chip-pgmt.sts-5 {
	background-color: #0077b5b3 !important;
	border-color: #0077b5b3 !important;
	color: #ffffff !important;
}
.chip-pgmt.sts-6{
	background-color: #0077B5 !important;
	border-color: #0077B5 !important;
	color: #ffffff !important;
}
.chip-pgmt.sts-7, .chip-pgmt.sts-monit-1 {
	background-color: #669900 !important;
	border-color: #669900 !important;
	color: #ffffff !important;
}
.chip-pgmt.sts-8, .chip-pgmt.sts-monit-4 {
	background-color: #CC0000 !important;
	border-color: #CC0000 !important;
	color: #ffffff !important;
}

/*  */

.rw-input,
input.form-control[disabled],
textarea.form-control[disabled] {
	color: #000000 !important;
}

.arrow-anim-pgmt {
	width: 20px;
	height: 20px;
	border: 2px solid #630A37;
	transition: all 0.3s;
}
.arrow-anim-pgmt.checked {
	transform: rotate(180deg);
}

.cst-checkbox label {
	margin: 0;
}
.cst-checkbox label span {
	padding: 0;
}

/* CARDS PGMT */

.cards-pgmt > div {
	min-height: 170px;
}
.cards-pgmt > div .cards-right > div {
	min-height: 63px;
}
.cards-pgmt .title-pgmt {
	top: -10px;
	left: 10px;
	background-color: #fffcfb;
	color: #A57C7C;
}
.border-content-pgmt {
	border: 1px dashed #888888;
}
.border-bottom-card-pgmt-base,
.border-bottom-card-pdd-base,
.border-bottom-card-cr-base {
	border-bottom: 14px solid #ffffff;
}
.border-bottom-card-pgmt-base .card-pgmt-warn {
	min-height: 25px;
}
.border-bottom-card-pgmt-base.Disponivel,
	.border-bottom-card-pdd-base.Cadastrado,
	.border-bottom-card-cr-base.Cadastrado {
	border-color: #F0D975;
}
.border-bottom-card-pdd-base.Cadastro.Pendente,
.border-bottom-card-cr-base.CadastroPendente {
	border-color: #B8B8B8;
}
.border-bottom-card-pgmt-base.Fechado,
.border-bottom-card-pgmt-base.EmValidacao,
.border-bottom-card-pdd-base.Aprovado {
	border-color: #F0C105;
}
.border-bottom-card-pgmt-base.Validado,
.border-bottom-card-pdd-base.Escriturado {
	border-color: #99C9E1;
}
.border-bottom-card-pgmt-base.Lancado,
.border-bottom-card-pdd-base.Exportado,
.border-bottom-card-cr-base.Aprovado {
	border-color: #4DA0CC;
}
.border-bottom-card-pgmt-base.Agendado {
	border-color: #0077B5;
}
.border-left-card-pgmt-base.Pago,
.border-bottom-card-pdd-base.Pago,
.border-bottom-card-cr-base.Exportado {
	border-color: #669900;
}
.border-bottom-card-pdd-base.Pedido {
	border-color: #630A38;
}
.border-bottom-card-pdd-base.Movimentacao {
	border-color: #DF6378;
}
.border-left-card-pgmt-base.Revisado,
.border-left-card-pdd-base.ARevisar,
.border-left-card-cr-base.Arevisar {
	border-color: #CC0000;
}
.border-left-card-pdd-base.Cancelado,
.border-left-card-cr-base.Cancelado {
	border-color: #CC6200;
}
.border-left-card-pdd-base.NaoPago {
	border-color: #f87777;
}
.border-left-card-pgmt-base,
.border-left-card-pdd-base,
.border-left-card-cr-base {
	border-left: 6px solid #ffffff;
}

.border-left-card-pgmt-base > div:nth-child(2) > div,
.border-left-card-pdd-base > div:nth-child(2) > div,
.border-left-card-cr-base > div:nth-child(2) > div  {
	width: max-content;
}

.border-bottom-card-pgmt-base > div > p:nth-child(1).lgt-sm,
.border-bottom-card-pdd-base > div > p:nth-child(1).lgt-sm,
.border-bottom-card-cr-base > div > p:nth-child(1).lgt-sm {
	font-size: 1.55vw;
}

.border-bottom-card-pgmt-base > div > p:nth-child(1),
.border-bottom-card-pdd-base > div > p:nth-child(1),
.border-bottom-card-cr-base > div > p:nth-child(1) {
	font-size: 2.5vw;
}

@media (max-width: 1199px) {
	.border-bottom-card-pgmt-base > div > p:nth-child(1).lgt-sm,
	.border-bottom-card-pdd-base > div > p:nth-child(1).lgt-sm,
	.border-bottom-card-cr-base > div > p:nth-child(1).lgt-sm {
		font-size: 3vw;
	}
	.border-bottom-card-pgmt-base > div > p:nth-child(1),
	.border-bottom-card-pdd-base > div > p:nth-child(1),
	.border-bottom-card-cr-base > div > p:nth-child(1) {
		font-size: 4vw;
	}
}

/* TABLE PGMT */

.tr-status .td-status .circle-status {
	min-width: 14px;
	max-width: 14px;
	min-height: 14px;
	max-height: 14px;
	border: 2px solid #888888;
	background-color: #ffffff;
	aspect-ratio: 1/1;
}

.workflow-tag {
	border: 1px solid #888888 !important;
	background-color: #ffffff;
}

.tr-status.sts-9 {
	background-color: #FFE7E7 !important;
}
.tr-status.sts-7 {
	background-color: #E0F6E6 !important;
}

.tr-status .td-status .circle-status.sts-1,
.tr-status .td-status .circle-status.sts-pd-1,
.tr-status .td-status .circle-status.sts-cr-1,
.tr-status .td-status .circle-status.sts-No.Prazo,
.tr-status .td-status .circle-status.sts-monit-2,
.tr-status .td-status .circle-status.sts-ass-1,
.tr-status .td-status .circle-status.sts-regu-1,
.workflow-tag.sts-1 {
	background-color: #B8B8B8 !important;
	border-color: #B8B8B8 !important;
	color: #ffffff;
}
.tr-status .td-status .circle-status.sts-2,
.tr-status .td-status .circle-status.sts-pd-2,
.tr-status .td-status .circle-status.sts-cr-2,
.tr-status .td-status .circle-status.sts-passivo-1,
.tr-status .td-status .circle-status.sts-forn-1,
.tr-status .td-status .circle-status.sts-ass-2,
.tr-status .td-status .circle-status.sts-regu-2,
.workflow-tag.sts-2 {
	background-color: #F0D975 !important;
	border-color: #F0D975 !important;
}
.tr-status .td-status .circle-status.sts-3,
.tr-status .td-status .circle-status.sts-pd-3,
.tr-status .td-status .circle-status.sts-Período.de.Apuração,
.tr-status .td-status .circle-status.sts-monit-3,
.workflow-tag.sts-3 {
	background-color: #F0C105 !important;
	border-color: #F0C105 !important;
}
.tr-status .td-status .circle-status.sts-4,
.tr-status .td-status .circle-status.sts-pd-4,
.tr-status .td-status .circle-status.sts-cr-3,
.tr-status .td-status .circle-status.sts-ass-3,
.workflow-tag.sts-4 {
	background-color: #0077b566 !important;
	border-color: #0077b566 !important;
}
.tr-status .td-status .circle-status.sts-5,
.tr-status .td-status .circle-status.sts-pd-5,
.tr-status .td-status .circle-status.sts-forn-2,
.tr-status .td-status .circle-status.sts-ass-4,
.workflow-tag.sts-5 {
	background-color: #0077b5b3 !important;
	border-color: #0077b5b3 !important;
}
.tr-status .td-status .circle-status.sts-6,
.workflow-tag.sts-6 {
	background-color: #0077B5 !important;
	border-color: #0077B5 !important;
	color: #ffffff;
}
.tr-status .td-status .circle-status.sts-7,
.tr-status .td-status .circle-status.sts-pd-6,
.tr-status .td-status .circle-status.sts-cr-4,
.tr-status .td-status .circle-status.sts-passivo-3,
.tr-status .td-status .circle-status.sts-Finalizado,
.tr-status .td-status .circle-status.sts-monit-1,
.tr-status .td-status .circle-status.sts-forn-3,
.tr-status .td-status .circle-status.sts-ass-5,
.tr-status .td-status .circle-status.sts-regu-3,
.workflow-tag.sts-7 {
	background-color: #669900 !important;
	border-color: #669900 !important;
	color: #ffffff;
}
.tr-status .td-status .circle-status.sts-8,
.tr-status .td-status .circle-status.sts-pd-7,
.tr-status .td-status .circle-status.sts-cr-6,
.tr-status .td-status .circle-status.sts-passivo-2,
.tr-status .td-status .circle-status.sts-Vencido,
.tr-status .td-status .circle-status.sts-monit-4,
.workflow-tag.sts-8 {
	background-color: #CC0000 !important;
	border-color: #CC0000 !important;
	color: #ffffff;
}
.tr-status .td-status .circle-status.sts-pd-8,
.tr-status .td-status .circle-status.sts-cr-5 {
	background-color: #CC6200 !important;
	border-color: #CC6200 !important;
	color: #ffffff;
}

.workflow-tag.sts-9,
.tr-status .td-status .circle-status.sts-9 {
	background-color: #f87777 !important;
	border-color: #f87777 !important;
	color: #ffffff;
}

.tr-status .td-status .circle-status.sts-hist-1 {
	background-color: #F0D975 !important;
	border-color: #F0D975 !important;
}
.tr-status .td-status .circle-status.sts-hist-2 {
	background-color: #F0C105 !important;
	border-color: #F0C105 !important;
}
.tr-status .td-status .circle-status.sts-hist-3 {
	background-color: #0077b566 !important;
	border-color: #0077b566 !important;
}

.workflow-tag.sts-hist-1 {
	background-color: #F0D975 !important;
	border-color: #F0D975 !important;
}
.workflow-tag.sts-hist-2 {
	background-color: #F0C105 !important;
	border-color: #F0C105 !important;
}
.workflow-tag.sts-hist-3 {
	background-color: #0077b566 !important;
	border-color: #0077b566 !important;
}

/* MULTISELECT PGMT */
.rw-open>.rw-widget-picker {
	border-radius: 4px !important;
}
.rw-multiselect {
	margin-bottom: 16px;
}
.rw-multiselect .rw-widget-picker {
	display: flex;
	align-items: center;
}
.rw-multiselect .rw-widget-picker > div {
	display: flex;
	align-items: center;
	min-height: 36px;
}
.rw-multiselect .rw-input-reset {
	min-height: 36px !important;
	padding: 0px 8px !important;
}
.rw-multiselect-taglist {
	padding: 2px !important;
}
.rw-multiselect-tag {
	margin: 2px !important;
	font-size: 85% !important;
	border: none !important;
	border-radius: 2px !important;
	padding: 0 !important;
	background-color: #e9e9e9 !important;
	min-height: 23px;
}
.rw-multiselect-tag span {
	padding: 0 6px !important;
}
.rw-multiselect-tag button {
	font-weight: bold;
    color: #000;
	padding: 0;
	margin: 0;
    font-size: 16px;
}
.rw-multiselect.rw-state-readonly .rw-widget-input {
	background-color: #8080802c !important;
	border: 1px solid #B8B8B8 !important;
    border-radius: 4px !important;
}

.rw-multiselect.rw-state-readonly .rw-widget-input .rw-select {
	display: none;
}

.rw-multiselect.rw-state-readonly .rw-widget-input,
.rw-multiselect.rw-state-readonly .rw-widget-input ul li {
	cursor: auto;
	font-weight: 400;
}
.rw-multiselect.rw-state-readonly .rw-widget-input ul li {
	background-color: #ffffff !important;
	padding: 0 2px !important;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.07);
}
.rw-multiselect.rw-state-readonly .rw-widget-input ul li > div {
	display: none;
}
.rw-multiselect-tag button:hover {
    color: #de350b;
	background-color: #ffbdad;
}
.rw-widget-input, .rw-filter-input {
	border: 1px solid #B8B8B8 !important;
	box-shadow: none !important;
}
.rw-state-focus>.rw-widget-container {
	box-shadow: none !important;
	border: 1px solid #B8B8B8 !important;
    border-radius: 4px;
	color: #000000;
}
.rw-popup {
	padding: 0.3rem;
	border-top: none !important;
	border-color: #B8B8B8 !important;
	box-shadow: none !important;
}
.rw-list-option.rw-state-focus {
	border: none;
}
.rw-popup-container {
	top: 120% !important;
}
.rw-popup-container .rw-popup {
	border: 0px solid transparent !important;
	border-radius: 3px !important;
	box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1) !important;
	padding: 0px !important;
}
.rw-popup-container .rw-popup ul li {
	padding: 6px 12px !important;
}
.rw-popup-container .rw-popup ul li:hover {
	background-color: #deebff !important;
}
.rw-popup-container .rw-popup ul li.rw-list-empty {
	background-color: transparent !important;
	text-align: center;
}

/* MULTISELECT PASSIVO */
.rw-multiselect.clear-inside .rw-input-reset, 
.rw-multiselect.clear-inside .rw-select {
	display: none;
}

/* MULTISELECT VOTOS */
.rw-widget>.rw-widget-container.dropVotos {
	border: none !important;
}
.rw-widget>.rw-widget-container.dropVotos .rw-input {
	padding-left: 30px;
}
.rw-widget.rw-state-disabled>.rw-widget-container.dropVotos .rw-select {
	visibility: hidden;
}
.rw-widget>.rw-widget-container.dropVotos ~ .rw-popup-container ul li {
	padding: 4px 8px !important;
}
.rw-widget>.rw-widget-container.dropVotos.success .rw-input {
	color: #198754 !important;
}
.rw-widget>.rw-widget-container.dropVotos.danger .rw-input {
	color: #DC3545 !important;
}
.rw-widget>.rw-widget-container.dropVotos.neutral .rw-input {
	color: #900E37 !important;
}

/* LINK CODE ERRO NOTIFICATION */
.link-code {
	font-weight: bold;
    text-decoration: underline;
	color: #ffda18 !important;
}

/*  */

.txt-grey {
	color: #888888 !important;
}

/* WIDTHS */

.w-mx-cnt {
	width: max-content !important;
}

.mmw-45px {
	min-width: 45px;
	max-width: 45px;
}

.mw-100px {
	min-width: 100px;
}

.w-30 {
	width: 30%;
}

.w-70 {
	width: 70%;
}

/* CONTROLA SLA */

.h-controle-sla-chip {
	height: 20px !important;
}

/* TEXTAREAS */

.txtarea-fechamento {
	min-height: 100px !important;
}
.diario-txtarea {
	min-height: 160px !important;
	resize: none;
}
.txtarea-fechamento-auto {
	min-height: max-content !important;
}

/* TOOLTIPS */
.__react_component_tooltip {
	z-index: 1201 !important;
	padding: 0px 6px !important;
	box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
}
.__react_component_tooltip.place-top:after {
	content: "";
    position: absolute;
    top: 100%;
    border-width: 6px;
    border-style: solid;
}
	/* LIGHT */
.__react_component_tooltip.type-light {
	color: #161616;
	background-color: #ffffff;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
}
.__react_component_tooltip.type-light.place-top:after {
	border-color: #ffffff transparent transparent transparent;
}
	/* DARK */
.__react_component_tooltip.type-dark {
	color: #f7f7f7;
	background-color: #333333;
}
.__react_component_tooltip.type-dark.place-top:after {
	border-color: #333333 transparent transparent transparent;
}
	/* OPEA */
.__react_component_tooltip.type-opea {
	color: #ffffff;
	background-color: #630A37;
	box-shadow: none;
}
.__react_component_tooltip.type-opea.place-top:after {
    border-color: #630A37 transparent transparent transparent;
}
	/* ERROR */
.__react_component_tooltip.type-error {
	background-color: #dc3545;
}
.__react_component_tooltip.type-error.place-top:after {
	border-color: #dc3545 transparent transparent transparent;
}

/*  */

.inputPgmt 	*[class*="-placeholder"] {
	white-space: nowrap !important;
}
.inputPgmt 	[class*="singleValue"] {
	color: #000000;
	font-weight: 400;
}

.line-collapse {
	cursor: pointer;
}
.line-collapse .arrow-anim-pgmt {
	opacity: 0.4;
	right: 0;
	transition: 0.3s;
	pointer-events: none;
}
.line-collapse .line-inter {
	background-color: #6666661f;
    border-radius: 10px;
    border: 1px solid transparent;
	transition: all 0.3s;
	height: 1px;
	left: 0;
	width: calc(100% - 28px);
}
.line-collapse:hover .line-inter {
	border-color: #6666661f;
}
.line-collapse:hover .arrow-anim-pgmt {
	opacity: 1;
}

/*  */

.break-xxl-expand {
	flex: 0 0 50%;
    max-width: 50%;
}
.mt-2-xxl-expand {
	margin-top: 0;
}

@media (max-width: 1365px) {
	.break-xxl-expand {
		flex: 0 0 100%;
    	max-width: 100%;
	}
	.mt-2-xxl-expand {
		margin-top: 8px;
	}
}

/* HISTÓRICO CARTEIRA */

[id^="rowTable-"].hide {
	display: none;
}
[id^="rowTable-"] td {
	border: none !important;
}
[id^="rowTable-"]:hover {
	background-color: transparent !important;
}

/* FILE DONWLOAD - RELATÓRIOS GERENCIAIS */

.file-download-size {
   width: 30px !important;
   height: 22px !important;
}


/*  */

.dt-cota .icon-arrow {
	transition: transform 0.5s ease, opacity 1s ease, color 0.5s ease;
	opacity: 1;
}

.dt-cota .icon-arrow.animdown {
	transform: rotate(0deg);
	color: #c41f1f;
}

.dt-cota .icon-arrow.animtop {
	transform: rotate(180deg);
	color: #156e2a;
}

.dt-cota .icon-arrow.animhide {
	transform: rotate(90deg) scale(0);
	opacity: 0;
	color: transparent;
}

/* OPCÕES DO SISTEMA */

.input-file-opcao {
	border: 1px solid #CED4DA;
	/* max-width: 90% !important; */
}

.input-file-opcao .name-place {
	border: 1px solid #CED4DA;
	background-color: #E9ECEF;
	color: #6C757D;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*  */

/* .dv-error {
	background-color: #ffffff;
	transition: transform 0.2s;
}

@keyframes pulse {
	0% { background-color: #ff990098 }
	50% { background-color: #ff990098 }
    100% { background-color: #ffffff }
}

@keyframes vibrate {
    0% { transform: translate(0, 0) }
    25% { transform: translate(5px, -5px) }
    50% { transform: translate(-5px, 5px) }
    75% { transform: translate(5px, -5px) }
    100% { transform: translate(0, 0) }
}

.pulse-and-vibrate {
    animation: pulse 4s linear 1, vibrate 0.2s linear 4;
} */

/* FIXED FLOATER */

.fixed-floater-arrow {
	position: fixed;
	top: 200px;
    right: 8px;
	z-index: 1205;
	transition: opacity 0.3s;
	opacity: 1;
}

.fixed-floater-arrow.hide {
	pointer-events: none;
	opacity: 0;
}

.fixed-floater-content {
	position: fixed;
    top: 200px;
    right: 10px;
    z-index: 1205;
    max-width: 400px;
    max-height: 500px;
	transition: all 0.3s;
}

.fixed-floater-content.hide {
	right: -25rem;
}

.fixed-floater-content .dv-messages {
	overflow-x: hidden;
	overflow-y: auto;
	height: inherit;
	max-height: 400px;
	margin: 8px 0;
}

.fixed-floater-content .dv-messages::-webkit-scrollbar {
	width: 8px;
}
.fixed-floater-content .dv-messages::-webkit-scrollbar-track {
	background: #f1f1f1; 
}
.fixed-floater-content .dv-messages::-webkit-scrollbar-thumb {
	background: #88888875; 
}
.fixed-floater-content .dv-messages::-webkit-scrollbar-thumb:hover {
	background: #5555558f; 
}

/*  */

.bread-regu span {
	opacity: 0.3;
	transition: all 0.3s;
}
.bread-regu span:hover {
	opacity: 1;
}

/*  */

.disabled-field .rw-input,
.disabled-field input.form-control,
.disabled-field textarea.form-control {
	background-color: #8080802c !important;
	cursor: auto !important;
	padding: 8px !important;
	border: 1px solid #B8B8B8 !important;
	border-radius: 4px !important;
}

.pg-regu .disabled-field .rw-input,
.pg-regu .disabled-field input.form-control,
.pg-regu .disabled-field textarea.form-control {
	opacity: 0.5;
	cursor: no-drop !important;
	background-color: #ffffff !important;
}

textarea:disabled {
	background-color: #8080802c !important;
	cursor: no-drop;
}

/* INPUTS MONITORAMENTO */
.monit-blocked,
.monit-blocked.rw-state-readonly .rw-widget-input {
	border: none !important;
	height: inherit !important;
}
.monit-blocked::-webkit-calendar-picker-indicator {
    display: none;
}
.monit-blocked.rw-state-readonly .rw-widget-input .rw-input {
	padding: 0 !important;
}
.monit-blocked.rw-state-readonly .rw-widget-input .rw-select {
	display: none !important;
}

/* MODALS */

.modal-fullscreen {
	width: 100%;
	max-width: 100%;

	@media (min-width: 1440px) {
		width: 1400px !important;
	}

	@media (min-width: 1200px) {
		width: 1200px;
	}

	@media (min-width: 1024px) {
		width: 1024px;
	}
}

.modal-fullscreen-2 {
	width: 100%;
	max-width: 100%;

	@media (min-width: 1200px) {
		width: 1200px;
	}

	@media (min-width: 1024px) {
		width: 1024px;
	}
}

/* TABLES */

/* .tb-remessa {
  max-height: calc(100vh - 530px);
  overflow-y: auto;
  position: inherit;
}
.tb-remessa table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.tb-remessa th, .tb-remessa td {
  padding: 8px;
}
.tb-remessa thead th {
  position: sticky;
  padding-bottom: 8px !important;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
} */

/*  */

.bg-dark-grey {
	background-color: #f2f2f2;
}

/*  */

.w-500px {
	width: 500px;
}

.exception-style,
.message-style,
.logger-style {
	font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
	padding: 10px;
    border-radius: 5px;
    margin: 0 0 20px 0;
    white-space: pre-wrap;
    overflow: auto;
}

.exception-style {
    color: #B22222;
    background-color: #F8D7DA;
    border: 1px solid #b222227c;
}

.message-style {
    color: #c9c9c9;
    background-color: #353535;
    border: 1px solid #7c7c7c83;
}

.logger-style {
    color: #ffffff;
    background-color: #51b376;
    border: 1px solid #51b37788;
}

.btn-copy {
	transition: all 0.3s;
	background-color: #531f1f;
	border: 1px solid #531f1f77;
}

.btn-copy:hover {
	background-color: #471717;
}

/* FAQ */

.faq-row {
	border: 1px solid #AF3751;
	background-color: #E9ECEF;
}


/* MOBILE */

@media (max-width: 599px) {
	.wrapper > div:first-child > header {
		width: calc(100% - 57px) !important;
	}
	.wrapper > div:first-child > header:nth-child(2) > div {
		display: block;
		max-height: 84px;
		padding: 0 !important;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:last-child {
		max-height: 34px;
		padding: 0px 15px 0;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:last-child > div:first-child {
		max-height: 34px;
		justify-content: center;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:last-child > div:first-child > button {
		padding: 0;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:last-child > div:last-child {
		display: none;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:first-child {
		padding: 10px 30px;
	}
	.wrapper > div:first-child > header:nth-child(2) > div > div:first-child img[title="Logo Opea"] {
		height: 30px;
	}
	.wrapper > div:first-child > div {
		z-index: 9991;
	}
	.wrapper > div:first-child > div[class*="drawerOpen"] {
		position: absolute;
	}
	.wrapper main #topContent .container-fluid {
		transition: all 0.3s;
		padding-right: 10px !important;
    	padding-left: 10px !important;
	}


	.card.shadow-custom.rounded-12 .pagination li a {
		padding: 0px 10px !important;
		min-width: 25px !important;
		height: 25px !important;
		line-height: 25px !important;
	}
	.card.shadow-custom.rounded-12 > div:nth-child(2) {
		display: block !important;
	}
	.card.shadow-custom.rounded-12 .card-content.table-responsive table thead tr th {
		text-wrap: nowrap;
		padding-top: 6px;
	}
	.card.shadow-custom.rounded-12 .card-content.table-responsive table tbody tr td {
		padding: 6px 4px;
	}
	.card.shadow-custom.rounded-12 > div:nth-child(2) > div:first-child label {
		text-align: center !important;
		margin: 5px 0 !important;
	}
	.card.shadow-custom.rounded-12 > div:nth-child(2) > div:nth-child(2) label {
		padding-top: 8px !important;
		width: 100% !important;
		text-align: center !important;
		margin: 0 !important;
		margin-top: 8px !important;
		border-top: 1px solid #85858542 !important;
	}


	div.modalDialog[role="dialog"] {
		z-index: 9992;
	}
	div.modalDialog[role="dialog"] > div[class*="MuiDialog-container"] > div {
		margin: 0 !important;
		transition: all 0.3s !important;
		min-height: 100% !important;
	}
	div.modalDialog[role="dialog"] > div[class*="MuiDialog-container"] .modal-dialog .modal-content {
		min-height: 100% !important;
		transition: all 0.3s !important;
	}


	.login-page .page-header {
		padding: 0 !important;
	}
	.login-page .page-header > .container {
		padding: 0 !important;
		margin: auto !important;
	}
	.login-page .page-header > .container > div {
		margin: auto 32px !important;
	}
	.login-page .page-header > .container > div form > div:not(:nth-child(2)) {
		width: 100% !important;
	}
}

/* TRANSITION GROUP */
.item-enter {
	opacity: 0;
	transform: translateY(20px);
  }
  .item-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 300ms, transform 300ms;
  }
  .item-exit {
	opacity: 1;
	transform: translateY(0);
  }
  .item-exit-active {
	opacity: 0;
	transform: translateY(-20px);
	transition: opacity 300ms, transform 300ms;
  }

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
	background: #88888875; 
}
::-webkit-scrollbar-thumb:hover {
	background: #5555558f; 
}

/* EDITOR */
.ql-container.ql-snow {
	min-height: 150px;
}

/* CUSTOM COLOR OMNI */
.badge-user-omni {
	background-color: #e7e7e7;
	color: #630A38;
	padding: 4px 8px;
	font-size: 12px;
	font-weight: 400;
	width: max-content;
}

.badge-user-omni svg {
	width: 18px;
	height: 18px;
	margin-bottom: 2px;
	margin-left: 4px;
}